import {
  AppBar,
  Avatar,
  Button,
  Drawer,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
} from "@mui/material";
import React, { Fragment, useContext, useState } from "react";
import { default_avatar, logo } from "../../config/config";
import { Link, useHref, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { UserContext } from "../../contexts";
import { useFetch } from "../../utils/hooks/fetch";
import CartDrawer from "../cart_drawer";

const Navbar = () => {
  const { user, setUser } = useContext(UserContext);

  const { logout } = useFetch();

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = Boolean(anchorEl);

  const currentUrl = useHref();

  const shouldBeTransparent =
    currentUrl.includes("industries") ||
    currentUrl.includes("/resumes") ||
    currentUrl.includes("/profile/resume-public") ||
    currentUrl.includes("/profile/resume-private") ||
    currentUrl.includes("/offers") ||
    currentUrl.includes("login") ||
    currentUrl.includes("signup");

  const handleLogout = () => {
    logout();
    setAnchorEl(null);
    setUser(null);
    navigate("/login");
  };

  const list_links = [
    <Link target="_blank" to="https://talentsetpaillettes.com/">
      Accueil
    </Link>,
    <Link target="_blank" to="https://talentsetpaillettes.com/a-propos/">
      A propos
    </Link>,
    <Link
      to="/industries"
      className={currentUrl.includes("industries") ? "underline" : ""}
    >
      CVthèque
    </Link>,
    <Link
      to="/offers"
      className={currentUrl.includes("offers") ? "underline" : ""}
    >
      Offres
    </Link>,
    <Link target="_blank" to="https://talentsetpaillettes.com/blog/">
      Blog
    </Link>,
    <Link target="_blank" to="https://talentsetpaillettes.com/#contact">
      Contact
    </Link>,
  ];

  return (
    <AppBar
      position="static"
      style={{
        boxShadow: shouldBeTransparent && "none",
        background: shouldBeTransparent
          ? "transparent"
          : "linear-gradient(#006DB8, #053162)",
        minWidth: "100vw",
      }}
    >
      <Toolbar
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(3, 1fr)",
          height: "136px",
        }}
      >
        <Link to="/">
          <img
            src={logo}
            alt="logo"
            style={{ width: "25vw", maxWidth: "190px", minWidth: "150px" }}
          />
        </Link>

        <Stack
          direction="row"
          spacing={2}
          sx={{
            display: { xs: "none", sm: "none", lg: "block" },
            justifySelf: "center",
            textTransform: "uppercase",
            letterSpacing: "2.3px",
          }}
        >
          {list_links.map((link, index) => (
            <React.Fragment key={index}>{link}</React.Fragment>
          ))}
        </Stack>

        <Stack
          sx={{
            display: { xs: "none", sm: "none", lg: "block" },
            justifySelf: "end",
          }}
          className="logger"
        >
          {user ? (
            <Fragment>
              <Stack gap={3} direction="row" alignItems="center">
                {user.user_type === "recruiter" && <CartDrawer />}

                <Button
                  id="basic-button"
                  className="button-profile"
                  aria-controls={openMenu ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openMenu ? "true" : undefined}
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                  <Avatar
                    src={
                      user.profile_picture
                        ? user.profile_picture
                        : user.avatar
                        ? user.avatar
                        : default_avatar
                    }
                    alt={user.firstname}
                    sx={{ width: "60px !important", height: "60px !important" }}
                  />
                </Button>
              </Stack>
              {anchorEl && (
                <Menu
                  id="basic-menu"
                  open={openMenu}
                  onClose={() => setAnchorEl(null)}
                  anchorEl={anchorEl}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      setAnchorEl(null);
                      navigate("/profile");
                    }}
                  >
                    Profil
                  </MenuItem>

                  <MenuItem onClick={handleLogout}>Déconnection</MenuItem>
                </Menu>
              )}
            </Fragment>
          ) : (
            <Stack gap={3} direction="row" alignItems="center">
              <CartDrawer />

              <Stack
                direction="row"
                gap={1}
                sx={{ flexDirection: { lg: "column", xl: "row" } }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => navigate("/login")}
                  sx={{ width: "109px" }}
                >
                  Connexion
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => navigate("/signup")}
                  sx={{ width: "109px" }}
                >
                  Inscription
                </Button>
              </Stack>
            </Stack>
          )}
        </Stack>
        {/* burger menu */}
        <Stack
          sx={{
            display: { xs: "block", sm: "block", lg: "none" },
            gridColumn: "-1",
          }}
        >
          <Button onClick={() => setOpen(!open)}>
            <MenuIcon
              sx={{
                fontSize: "3rem",
                color: "white",
              }}
            />
          </Button>
        </Stack>
        {/* side menu */}
        <Drawer open={open} onClose={() => setOpen(false)}>
          <Stack
            justifyContent="space-between"
            sx={{
              width: "300px",
              height: "100%",
              padding: "20px 20px 40px 20px",
            }}
          >
            <Stack spacing={2}>
              <Button
                onClick={() => setOpen(false)}
                sx={{ alignSelf: "flex-end" }}
              >
                <CloseIcon />
              </Button>

              {list_links.map((link, index) => (
                <React.Fragment key={index}>{link}</React.Fragment>
              ))}
            </Stack>

            {user ? (
              <Stack
                direction="column"
                alignItems="center"
                gap={3}
                sx={{ width: "100%" }}
              >
                <Avatar
                  src={
                    user.profile_picture
                      ? user.profile_picture
                      : user.avatar
                      ? user.avatar
                      : default_avatar
                  }
                  alt={user.firstname}
                  sx={{ width: "80px !important", height: "80px !important" }}
                />

                {user.user_type === "recruiter" && <CartDrawer />}

                <Button
                  onClick={() => navigate("/profile")}
                  fullWidth
                  variant="outlined"
                  size="large"
                >
                  Mon profil
                </Button>

                <Button
                  onClick={handleLogout}
                  fullWidth
                  variant="contained"
                  size="large"
                >
                  Déconnection
                </Button>
              </Stack>
            ) : (
              <Stack gap={4} alignItems={"center"}>
                <CartDrawer />

                <Button
                  fullWidth
                  variant="outlined"
                  size="large"
                  onClick={() => navigate("/login")}
                >
                  Connexion
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  size="large"
                  onClick={() => navigate("/signup")}
                >
                  Inscription
                </Button>
              </Stack>
            )}
          </Stack>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
