import React, { Fragment, useContext, useEffect, useState } from "react";

import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Stack,
  TextField,
} from "@mui/material";

import { useFetch } from "../../utils/hooks/fetch";
import { UserContext } from "../../contexts";
import { toast } from "react-toastify";
import { gps_dot } from "../../config/config";

import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";

import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_test_51PdBVzEU2avZ58TnqhTp3zt3p5hOzYyXnlXXqG1F2onu0IOjqM2FXNuUnp2IRKT416tURgn7H6wbq9lU97MHCtdj00AtTlGjBp"
);

const steps = ["Adresse de facturation", "Paiement"];

const PaimentProcess = ({ useCredits }) => {
  const { user, setUser } = useContext(UserContext);

  const [activeStep, setActiveStep] = useState(0);
  const [stripeOtions, setStripeOtions] = useState({
    clientSecret: null,
  });

  const [userBillingInformation, setUserBillingInformation] = useState({
    address: user.address,
    address_complement: user.address_complement,
    zip_code: user.zip_code,
    city: user.city,
    country: user.country,
  });

  const { data: stripeSession, post, loading } = useFetch(true);
  const { data: userUpdate, patch, loading: patchLoading } = useFetch(true);

  const handleChange = (e) => {
    setUserBillingInformation({
      ...userBillingInformation,
      [e.target.name]: e.target.value,
    });
  };

  const handleNext = () => {
    if (!isAddressCompleted()) {
      toast.info("Veuillez renseigner vos informations de facturation");
      return;
    }
    patch(`/recruiter/${user.id}`, userBillingInformation);
  };

  useEffect(() => {
    if (stripeSession) {
      setStripeOtions({
        clientSecret: stripeSession.clientSecret,
      });
      setActiveStep(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripeSession]);

  useEffect(() => {
    if (userUpdate) {
      setUser({
        ...user,
        ...userBillingInformation,
      });
      post("/create-checkout-session", {
        user: user,
        useCredits: useCredits,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userUpdate]);

  const isAddressCompleted = () => {
    return (
      userBillingInformation.address &&
      userBillingInformation.zip_code &&
      userBillingInformation.country &&
      userBillingInformation.city
    );
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "60vw",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        maxHeight: "90vh",
        overflowY: "auto",
      }}
    >
      <Stepper activeStep={activeStep} sx={{ mb: 2, maxWidth: "400px" }}>
        {steps.map((label) => {
          return (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <Fragment>
        {activeStep === 0 && (
          <Stack direction="row" justifyContent="space-between">
            <Stack sx={{ width: "100%", maxWidth: "800px" }} mt={2}>
              <Typography
                variant="inherit"
                sx={{
                  color: "gray",
                  fontSize: "12px !important",
                  lineHeight: "16px !important",
                }}
              >
                Il est impératif de renseigner une adresse de facturation valide
                afin de procéder au paiement.
                <br />
                Merci de vérifier les informations saisies.
              </Typography>
              <Stack spacing={2} sx={{ mt: 2 }} direction="row">
                <TextField
                  fullWidth
                  label="Adresse *"
                  name="address"
                  value={userBillingInformation.address}
                  onChange={handleChange}
                  variant="standard"
                />
              </Stack>
              <Stack spacing={2} sx={{ mt: 2 }} direction="row">
                <TextField
                  fullWidth
                  label="Complément d'adresse"
                  name="address_complement"
                  value={userBillingInformation.address_complement}
                  onChange={handleChange}
                  variant="standard"
                />
              </Stack>
              <Stack spacing={2} sx={{ mt: 2 }} direction="row">
                <TextField
                  fullWidth
                  label="Code postal *"
                  name="zip_code"
                  value={userBillingInformation.zip_code}
                  onChange={handleChange}
                  variant="standard"
                />
                <TextField
                  fullWidth
                  label="Ville *"
                  name="city"
                  value={userBillingInformation.city}
                  onChange={handleChange}
                  variant="standard"
                />

                <TextField
                  fullWidth
                  label="Pays *"
                  name="country"
                  value={userBillingInformation.country}
                  onChange={handleChange}
                  variant="standard"
                />
              </Stack>
            </Stack>
            <img src={gps_dot} alt="gps_dot" style={{ marginRight: "auto", marginLeft: "auto" }} />
          </Stack>
        )}
        {activeStep === 1 && (
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={stripeOtions}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          {activeStep === 1 && (
            <Button
              variant="outlined"
              onClick={() => setActiveStep(activeStep - 1)}
              sx={{ mr: 1 }}
            >
              Retour
            </Button>
          )}
          <Box sx={{ flex: "1 1 auto" }} />

          {activeStep === 0 && (
            <Button
              variant="contained"
              disabled={patchLoading || loading}
              onClick={handleNext}
            >
              Suivant
            </Button>
          )}
        </Box>
      </Fragment>
    </Box>
  );
};

export default PaimentProcess;
