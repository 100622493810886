import { Divider, Stack } from "@mui/material";
import React from "react";

const DividerPoint = () => {
  return (
    <Stack
      direction="column"
      alignItems="center"
      sx={{ width: "100%", height: "120px", mt: 2 }}
    >
      <Divider
        sx={{ borderWidth: "1px !important", height: "100px" }}
        orientation="vertical"
      />
      <div
        style={{
          width: "10px",
          height: "10px",
          backgroundColor: "rgb(255, 194, 13)",
          borderRadius: "50%",
        }}
      />
    </Stack>
  );
};

export default DividerPoint;
