import React from "react";
import { Stack, CircularProgress } from "@mui/material";

const Loader = () => {
  return (
    <Stack
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, 0%)",
        zIndex: "5",
        width: "fit-content",
      }}
    >
      <CircularProgress color="primary" />
    </Stack>
  );
};

export default Loader;
