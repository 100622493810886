import React, { Fragment } from 'react'
import {
    background_header_cvtheque,
    background_left_pail,
    background_right_pail,
} from "../../config/config";
import {
Stack,
} from "@mui/material";

const FullBackground = ({ children }) => {
  return (
    <Fragment>
        <Stack style={{ backgroundImage: `url(${background_header_cvtheque})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', height: '600px', position: 'absolute', width: '100vw', zIndex: '-1', top: '0' }}>
            <img
            src={background_left_pail}
            alt="background"
            style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "20vw",
                minWidth: "200px",
                zIndex: -1,
            }}
            />
            <img
            src={background_right_pail}
            alt="background"
            style={{
                position: "absolute",
                zIndex: -1,
                width: "20vw",
                minWidth: "200px",
                right: 0,
                bottom: "100px",
            }}
            />
        </Stack>
        {children}
    </Fragment>
  )
}

export default FullBackground