import { useState } from "react";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

import {
  API_URL,
  COOKIE_ID,
  COOKIE_TOKEN,
  COOKIE_TYPE,
} from "../../config/config";

export const useFetch = (withAuth = false) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [token, setToken] = useState(Cookies.get(COOKIE_TOKEN));
  const [errors, setErrors] = useState(null);
  const [status, setStatus] = useState(null);

  const headers = {
    "Content-Type": "application/json",
  };

  if (withAuth && token) {
    headers.Authorization = token;
  }

  const get = (path) => {
    setLoading(true);
    setData(null);
    setErrors(null);

    fetch(API_URL + path, {
      method: "GET",
      headers,
    })
      .then((response) => {
        setStatus(response.status);

        return response.json();
      })
      .then((data) => {
        if (data.errors) {
          setErrors(data.errors);
        } else {
          setData(data);
        }
      })
      .catch((errors) => setErrors(errors))
      .finally(() => {
        setLoading(false);
      });
  };

  const post = (path, postData, stringify = true) => {
    setLoading(true);
    setData(null);
    setErrors(null);

    if (!stringify) {
      delete headers["Content-Type"];
    }

    var options = {
      method: "POST",
      headers,
      body: stringify ? JSON.stringify(postData) : postData,
    };

    fetch(API_URL + path, options)
      .then((response) => {
        setStatus(response.status);

        return response.json();
      })
      .then((data) => {
        if (data.errors) {
          setErrors(data.errors);
        } else {
          setData(data);
        }
      })
      .catch((error) => setErrors(error))
      .finally(() => setLoading(false));
  };

  const patch = (path, postData, stringify = true) => {
    setLoading(true);
    setData(null);
    setErrors(null);

    if (!stringify) {
      delete headers["Content-Type"];
    }

    var options = {
      method: "PATCH",
      headers,
      body: stringify ? JSON.stringify(postData) : postData,
    };

    fetch(API_URL + path, options)
      .then((response) => {
        setStatus(response.status);

        return response.json();
      })
      .then((data) => {
        if (data.errors) {
          setErrors(data.errors);
        } else {
          setData(data);
        }
      })
      .catch((errors) => setErrors(errors))
      .finally(() => {
        setLoading(false);
      });
  };

  const destroy = (path, postData, stringify = true) => {
    setLoading(true);
    setData(null);
    setErrors(null);

    if (!stringify) {
      delete headers["Content-Type"];
    }

    var options = {
      method: "DELETE",
      headers,
      body: stringify ? JSON.stringify(postData) : postData,
    };

    fetch(API_URL + path, options)
      .then((response) => {
        setStatus(response.status);

        return response.json();
      })
      .then((data) => {
        if (data.errors) {
          setErrors(data.errors);
        } else {
          setData(data);
        }
      })
      .catch((errors) => setErrors(errors))
      .finally(() => {
        setLoading(false);
      });
  };

  const logout = () => {
    setToken(null);
    Cookies.remove(COOKIE_TOKEN);
    Cookies.remove(COOKIE_ID);
    Cookies.remove(COOKIE_TYPE);
    toast.error("Vous êtes déconnecté");
  };

  return {
    loading,
    errors,
    status,
    data,
    token,
    headers,
    get,
    post,
    patch,
    destroy,
    logout,
  };
};
