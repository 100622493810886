import React, { Fragment, useContext, useState, useEffect } from "react";
import { useFetch } from "../../utils/hooks/fetch";
import { UserContext } from "../../contexts";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";

export default function HiddenFile({ setPreview, preview }) {
  const { user, setUser } = useContext(UserContext);
  const { patch, data } = useFetch(true);
  const [profilePicFile, setProfilePicFile] = useState();

  const readImageFile = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSendProfilPic = (e) => {
    const data = new FormData();
    data.append("profile_picture", profilePicFile);

    const uri =
      user.user_type === "recruiter"
        ? `/recruiter/${user.id}`
        : `/user/${user.id}`;
    patch(uri, data, false);
  };

  useEffect(() => {
    if (data) {
      setUser(data);
      setPreview("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <Fragment>
      <input
        type="file"
        id="hidden-input-file"
        name=""
        style={{ display: "none" }}
        onChange={(e) => {
          setProfilePicFile(e.target.files[0]);
          readImageFile(e.target.files[0]);
        }}
      />
      {!preview ? (
        <label htmlFor="hidden-input-file">
          <EditIcon />
        </label>
      ) : (
        <CheckIcon
          color="success"
          fontSize="large"
          onClick={(e) => {
            handleSendProfilPic(e);
          }}
        />
      )}
    </Fragment>
  );
}
