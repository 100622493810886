import { createTheme } from "@mui/material/styles";
import { frFR } from "@mui/material/locale";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const theme = createTheme(
  {
    typography: {
      fontFamily: ["Roboto", "Arial", "sans-serif"].join(","),
    },
  },
  frFR,
);

export default theme;
