import { lazy } from "react";

// Authentification
const Login = lazy(() => import("./Authentification/login"));
const ForgotPassword = lazy(() => import("./Authentification/forgot_password"));
const Register = lazy(() => import("./Authentification/Register"));
const RegisterUser = lazy(() => import("./Authentification/Register/user"));

// Profiles
const Profile = lazy(() => import("./Profile/show"));
const Candidate = lazy(() => import("./Candidate/show"));
// const ProfilRecruiter = lazy(() => import("./Profile/recruiter"));
// const ProfilCandidate = lazy(() => import("./Profile/candidate"));
// const ProfilUser = lazy(() => import("./Profile/user"));

// CVthéque
const Industries = lazy(() => import("./Resume/industries"));
const Resumes = lazy(() => import("./Resume"));
const Resume = lazy(() => import("./Resume/show"));

// Offer
const Offers = lazy(() => import("./Offer"));

// Purchase
const PurchaseCompleted = lazy(() => import("./PurchaseCompleted/show"));

// Error
const NotFound = lazy(() => import("./Error/404"));

export {
  Login,
  ForgotPassword,
  Register,
  RegisterUser,
  Profile,
  Industries,
  Resumes,
  Resume,
  Candidate,
  Offers,
  PurchaseCompleted,
  NotFound,
};
